module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-nprogress@2.8.0_gatsby@2.30.1/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#1c5796","showSpinner":false,"minimum":0.2},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@2.1.10_@material-ui+styles@4.11.5_gatsby@2.30.1/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
